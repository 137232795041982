<template>
    <Modal :contains-floating-button="true">
        <h1>Send tillægskontrakt</h1>
        <p class="send-contract-explainer">Når du fremsender en tillægskontrakt til din håndværker, modtager han/hun en
            mail med de bilag du har tilføjet. Herefter kan håndværkeren acceptere eller afvise det fremsendte.</p>
        <Form @submit="send" :processing="processing">
            <fieldset>
                <Input type="email" placeholder="Modtagers e-mail*" v-model="to" :required="true"
                       tooltip="Modtagers e-mail adresse - typisk kontaktens kontaktperson"/>
                <Input type="text" placeholder="Emne*" v-model="subject" :required="true"
                       tooltip="Emnefeltet modtageren kan se i deres mailprogram"/>
                <Textarea placeholder="Besked*" v-model="message" :required="true"
                          tooltip="Beskeden der skal sendes til modtageren"/>

                <template v-if="file">
                    <h2>Bilag</h2>
                    <ViewFile v-bind="file"/>
                </template>
            </fieldset>
            <ActionButton :float="true" icon="paperplane"/>
        </Form>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import Input from '@/components/inputs/Input.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';
import Form from '@/components/Form.vue';
import ViewFile from '@/components/ViewFile.vue';
import { additionMailsCollection } from '@/utils/collections';
import useState from '@/store';
import Textarea from '@/components/inputs/Textarea.vue';
import template from '@/utils/template';

export default {
	name: 'SendContract',
	components: {
		Modal,
		Form,
		Input,
		ViewFile,
		ActionButton,
		Textarea,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
		contract: {
			type: Object,
			required: true,
		},
		status: {
			type: String,
			default: null,
		},
		file: {
			type: Object,
			default: null,
		},
	},
	setup(props) {
		const to = props.contract.contact.email.toString();
		const state = useState();
		const { user, project } = state;
		const subject = props.status === 'SENT' ? 'Påmindelse om tillægskontrakt til godkendelse' : 'Ny tillægskontrakt til godkendelse';
		const body = props.status === 'SENT' ? `Dette er blot en venlig påmindelse om, at du har en tillægskontrakt til den eksisterende kontrakt ${props.contract.agreement.description}, der afventer godkendelse til projektet ${project.value.name}.` : `Som aftalt fremsendes hermed tillægskontrakt til godkendelse i forlængelse med allerede eksisterende kontrakt "${props.contract.agreement.description}" for projektet "${project.value.name}".`;
		const message = `Hej ${props.contract.contact.name}\n\n${body}\n\nVenlig hilsen\n${user.value.name}`;
		return { to, subject, message };
	},
	data() {
		return { processing: false };
	},
	methods: {
		async send() {
			this.processing = true;

			try {
				const token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
				const mailData = {
					to: this.to,
					message: {
						subject: this.subject,
						html: template({
							subject: this.subject,
							message: this.message.replace(/(?:\r\n|\r|\n)/g, '<br>'),
							button: {
								link: `https://app.selvbyggerplus.dk/accept/${this.id}/${token}`,
								text: 'Klik her for at se dokumenterne',
							},
							disclaimer: true,
						}),
					},
					status: 'SENT',
					token,
				};

				await additionMailsCollection(this.contract.id, this.id).add(mailData);
				await this.$router.replace({ name: 'view-contract', params: { id: this.contract.id } });
			} catch (err) {
				this.processing = false;
			}
		},
	},
};
</script>
